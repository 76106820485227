// checkout confirm page
import React, { useState } from "react";
import CheckoutLayout from "../../components/checkoutLayout";
import { Link, navigate } from "gatsby";
import CartSummary from "../../components/cartSummary";
import { useCart } from "react-use-cart";
import { formatPrice } from "../../utils";
import axios from "axios";

const CheckoutConfirm = ({ location }) => {
  const { items } = useCart();
  const totalPrice = items.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  );
  const [deliveryOption, setDeliveryOption] = useState({
    type: "Sofia-personal-delivery",
    price: 4.9,
  });
  const [paymentOption, setPaymentOption] = useState("");
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [hasOrdered, setHasOrdered] = useState(false);
  if (!location || !location.state) {
    // You can add optional redirect to home page here
    return (
      <div className="container">
        <p>
          Моля, първо попълнете данните от Стъпка 1 - Данни за клиента{" "}
          <Link className="text-muted" to="/cart">
            Към количката
          </Link>
        </p>
      </div>
    );
  }
  if (Math.round(totalPrice) < 30) {
    navigate("/cart");
    return <p>Грешно въвеждане на данните. Връщане към количката...</p>;
  }

  const { customerInfo, invoiceWanted, willBeDeliveredToAnotherAddress } =
    location.state;
  const {
    firstName,
    lastName,
    email,
    phone,
    address,
    city,
    country,
    postcode,
  } = customerInfo;
  const {
    firstName2,
    lastName2,
    phone2,
    address2,
    city2,
    country2,
    postcode2,
  } = customerInfo;
  const { firmName, firmId, firmVatId, firmCity, firmAddress } = customerInfo;
  const { comment } = customerInfo;
  const handleClick = async (e) => {
    e.preventDefault();
    setHasOrdered(true);
    console.log(
      typeof willBeDeliveredToAnotherAddress,
      willBeDeliveredToAnotherAddress
    );
    const orderDetails = {
      customerInfo,
      invoiceWanted,
      willBeDeliveredToAnotherAddress,
      deliveryOption,
      paymentOption,
      marketingConsent,
      items,
      totalPrice,
    };
    const response = await axios.post(
      "/.netlify/functions/order",
      orderDetails
    );
    navigate("/checkout/done/", {
      state: { orderDetails, orderId: response.data.orderId },
    });
    console.log("order submitted", orderDetails);
  };
  return (
    <CheckoutLayout>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active"></li>
            <li className="breadcrumb-item text-muted">
              <Link className="text-muted" to="/checkout">
                Стъпка 1 - Данни за клиента
              </Link>
            </li>
            <li className="breadcrumb-item active">
              <a className="text-secondary" href="#">
                Стъпка 2 - Преглед на поръчката
              </a>
            </li>
          </ol>
        </nav>
        <form onSubmit={handleClick}>
          <div className="row">
            <div className="col-6">
              {/*create customerInfo component*/}
              <div className="row">
                <div className="col-lg-7">
                  <h3>Данни за клиента</h3>
                  <p>
                    Име: {firstName} {lastName}
                  </p>
                  <p>Имейл: {email}</p>
                  <p>Телефон: {phone}</p>
                </div>
              </div>
              {/*create delivery address info component (Test if it is the correct data)*/}
              <div className="row">
                <div className="col-lg-7">
                  <h3>Данни за доставка</h3>
                  <p>
                    {postcode2 || postcode} {city2 || city},{" "}
                    {country2 || country}
                  </p>
                  <p>{address2 || address}</p>
                </div>
                {/*create invoice data component if invoiceWanted*/}
                {invoiceWanted ? (
                  <div className="row">
                    <div className="col-lg-7">
                      <h3>Данни за фактура</h3>
                      <p>{firmName}</p>
                      <p>{firmId}</p>
                      <p>{firmVatId}</p>
                      <p>{firmCity}</p>
                      <p>{firmAddress}</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <h3>Данни за фактура</h3>
                    <p>Не желая фактура</p>
                  </>
                )}
                {/*create comment component*/}
                <div className="row">
                  <div className="col-lg-7">
                    <h3>Специфични изисквания</h3>
                    <p>{comment || "Няма"}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <h2>Продукти</h2>
              <CartSummary deliveryPrice={deliveryOption.price} />
            </div>
          </div>
          <div className="row">
            {/*create shipment option radio buttons*/}
            <div className="col-lg-7">
              <h3>Изберете начин на доставка</h3>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  checked={deliveryOption.type == "Sofia-personal-delivery"}
                  name="deliveryOption"
                  id="deliveryOption1"
                  value="Sofia-personal-delivery"
                  onChange={(e) =>
                    setDeliveryOption({ type: e.target.value, price: 4.9 })
                  }
                />
                <label className="form-check-label" htmlFor="deliveryOption1">
                  Доставка в София {formatPrice(4.9)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="deliveryOption1"
                  value="Econt-address"
                  onChange={(e) =>
                    setDeliveryOption({ type: e.target.value, price: 6.0 })
                  }
                />
                <label className="form-check-label" htmlFor="deliveryOption1">
                  Доставка до адрес с Еконт {formatPrice(6.0)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="deliveryOption2"
                  value="Econt-office"
                  onChange={(e) =>
                    setDeliveryOption({ type: e.target.value, price: 7.0 })
                  }
                />
                <label className="form-check-label" htmlFor="deliveryOption2">
                  Доставка до офис с Еконт {formatPrice(7.0)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="deliveryOption1"
                  value="Speedy-address"
                  onChange={(e) =>
                    setDeliveryOption({ type: e.target.value, price: 6.92 })
                  }
                />
                <label className="form-check-label" htmlFor="deliveryOption1">
                  Доставка до адрес със Спиди {formatPrice(6.92)}
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="deliveryOption"
                  id="deliveryOption2"
                  value="Speedy-office"
                  onChange={(e) =>
                    setDeliveryOption({ type: e.target.value, price: 5.99 })
                  }
                />
                <label className="form-check-label" htmlFor="deliveryOption2">
                  Доставка до офис със Спиди {formatPrice(5.99)}
                </label>
              </div>
            </div>

            <p>
              Цените за доставка са ориентировъчни и зависят от куриерските
              фирми. Доставката се плаща отделно (на куриера).
            </p>
          </div>
          {/*create payment methods (bank transfer and cash) options radio buttons*/}
          <div className="row">
            <div className="col-lg-7">
              <h3>Изберете начин на плащане</h3>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentOption"
                  id="paymentOption1"
                  value="bankTransfer"
                  onChange={(e) => setPaymentOption(e.target.value)}
                  required
                />
                <label className="form-check-label" htmlFor="paymentOption1">
                  Банков превод
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="currentColor"
                    className="bi bi-bank2 mx-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z" />
                  </svg>
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentOption"
                  id="paymentOption1"
                  value="cash"
                  onChange={(e) => setPaymentOption(e.target.value)}
                  required
                />
                <label className="form-check-label" htmlFor="paymentOption1">
                  Наложен платеж
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    fill="currentColor"
                    className="bi bi-cash-coin mx-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                    />
                    <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                    <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                    <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
                  </svg>
                </label>
              </div>
            </div>
          </div>
          {/* Create optional marketingConsent checkbox */}
          <div className="row">
            <div className="col-lg-7">
              <div className="form-check fs-5">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="marketingConsent"
                  id="marketingConsent"
                  onChange={(e) => setMarketingConsent(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="marketingConsent">
                  Желая да получавам промоционални съобщения и новини (не
                  задължително)
                </label>
              </div>
            </div>
          </div>
          {/*create order button*/}
          <button
            type="submit"
            className="btn btn-lg btn-secondary"
            disabled={hasOrdered}
          >
            Поръчай
          </button>
        </form>
      </div>
    </CheckoutLayout>
  );
};
export default CheckoutConfirm;
